import * as React from "react"
import { useContext, useEffect, useState } from "react"
import Seo from "../../../components/Seo"
import Layout from "../../../components/Layout"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { getConfig } from "../../../config/index"
import {
  filterByVisibility,
  findSingleTaxonomyLeaf,
  getProductMeta,
  isProductFullyOutOfStock,
  isVendorFrozen,
  isVendorPaused,
} from "../../util/productUtil"
import { cleanString, equalsIgnoreCase } from "../../util/generalUtil"
import ImpactIcons from "../../../components/impact-icons/ImpactIcons"
import ProductDetailsAccordion from "./ProductDetailsAccordion"
import { useObjectQueryString } from "../../hooks/useQueryString"
import ProductList from "../../components/product-list/ProductList"
import FeatureTitle from "../../components/title/FeatureTitle"
import * as suggestionApi from "../../api/suggestionApi"
import { BasketContext } from "../../context/BasketProvider"
import LinkButton from "../../components/button/LinkButton"
import GiftCardAttributeFields from "./GiftCardAttributeFields"
import * as colors from "../../constants/colors"
import { linkBlue } from "../../constants/colors"
import * as tracker from "../../tracking/tracker"
import ProductShortDescription from "./ProductShortDescription"
import {
  PrimaryButton,
  ProductImageGallery,
  ProductQuantityInput,
  RequestQuoteFormStateType,
  RequestQuoteFormWrapper,
  VolumeDiscounts,
} from "components"
import ProductAttributeFields from "./ProductAttributeFields"
import RequestQuoteBox from "./RequestQuoteBox"
import { formatDate, isDateInTheFuture } from "../../util/dateUtil"
import Breadcrumbs from "../product-taxonomy/Breadcrumbs"
import { getFormattedPriceRange, ProductType, screenSizes, VariantType } from "model"

const Container = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${screenSizes.desktop}px) {
    flex-direction: column;
  }
`
const BrandPointsContainer = styled.div`
  display: flex;
  align-items: center;
`
const ProductListContainer = styled.div`
  margin-top: 60px;
`
const AccordionContainer = styled.div`
  @media (min-width: ${screenSizes.desktop}px) {
  }
`
const ContentContainer = styled.div`
  flex: 1;
  padding: 0 20px;

  @media (min-width: ${screenSizes.desktop}px) {
    max-width: 700px;
  }
  @media (max-width: ${screenSizes.desktop}px) {
    padding: 0 15px;
  }

  @media (max-width: ${screenSizes.smallMobile}px) {
    padding: 0 10px;
  }

  a {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 0.9em;
  }
`
const AddToBasketForm = styled.div``
const GalleryContainer = styled.div`
  flex: 1;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${screenSizes.desktop}px) {
    max-width: 100%;
  }
`
const Title = styled.h1`
  font-family: "Minion", "Times New Roman", serif;
  margin: 0;
  margin-bottom: 0px;
`
const OutOfStock = styled.p`
  color: ${colors.rouge};
  font-size: 0.7em;
  height: 20px;
  margin: 0;
`
const Price = styled.div`
  display: block;
  margin-top: 0;
  font-size: 1.2em;

  margin-bottom: 10px;
  font-weight: bold;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`
const CalculatedPrice = styled.div`
  font-weight: bold;
  margin-right: 10px;
`
const StyledProductQuantityInput = styled(ProductQuantityInput)`
  margin-right: 10px;
  width: 80px;
  height: 50px;
`
const BrandLinkContainer = styled.div`
  display: flex;
  align-items: center;
`
const BrandLink = styled(GLink)`
  padding-left: 5px;
  color: ${linkBlue};
`
const OutOfStockClearSelectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
const PreOrderText = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`
const Moq = styled.div`
  margin-bottom: 20px;
`
const BreadcrumbContainer = styled.div`
  padding: 20px 0;
  @media (max-width: ${screenSizes.desktop}px) {
    padding: 20px 15px;
  }

  @media (max-width: ${screenSizes.smallMobile}px) {
    padding: 20px 15px;
  }
`

interface PropsType {
  product: ProductType
}

let timeout

const ProductPage = ({ product }: PropsType) => {
  const [selectedAttributes, setSelectedAttributes] = useObjectQueryString("attribute", {})
  const [giftCardAttributes, setGiftCardAttributes] = useState({})
  const [variant, setVariant] = useState<VariantType>(null)
  const [quantity, setQuantity] = useState<number>(product.moq)
  const [suggestions, setSuggestions] = useState([])
  const basketContext = useContext(BasketContext)
  const taxonomyLeaf = findSingleTaxonomyLeaf(product.categories)
  const isQuotable = product.isMerch || product.isQuotable

  const outOfStock = variant
    ? variant.stockStatus === "OUT_OF_STOCK"
    : isProductFullyOutOfStock(product)

  const soonestDeliveryDate =
    product.soonestDeliveryDate && isDateInTheFuture(product.soonestDeliveryDate)
      ? formatDate(product.soonestDeliveryDate)
      : null

  useEffect(() => {
    const init = async () => {
      updateVariant(findVariantFromAttributes(selectedAttributes, product.variants))
      suggestionApi.getSuggestions([]).then(suggestions => setSuggestions(suggestions))
      tracker.productView(product)
    }
    init()
  }, [])

  const updateQuantity = (newQuantity: number) => {
    clearTimeout(timeout)
    setQuantity(newQuantity)
    timeout = setTimeout(() => {
      if (newQuantity < product.moq) {
        setQuantity(product.moq)
      } else {
        setQuantity(newQuantity)
      }
    }, 1000)
  }

  const getQuantity = (): number => {
    if (quantity < product.moq) {
      return product.moq
    } else {
      return quantity
    }
  }

  const handleAttributeChange = (name, value) => {
    const newAttributes = { ...selectedAttributes, [name]: value }
    setSelectedAttributes(newAttributes)
    updateVariant(findVariantFromAttributes(newAttributes, product.variants))
  }

  const updateVariant = (variant: VariantType) => {
    if (variant) {
      tracker.variantSelected(variant)
    }
    setVariant(variant)
  }

  const addToBasket = () => {
    const meta = {
      ...getProductMeta(product),
      ...giftCardAttributes,
      deliveryDate: undefined,
    }
    if (product?.vendor?.slug === "social-supermarket-hampers") {
      const sameItemInBasket = basketContext.basket.items.find(
        basketItem =>
          product.productId === basketItem.product.productId &&
          variant?.variantId === basketItem.variant?.variantId
      )
      if (sameItemInBasket) {
        meta.deliveryDate = sameItemInBasket.deliveryDate
      }
    }
    basketContext.addProduct(product, variant, getQuantity(), meta, true).catch(() => {
      alert("Product out of stock")
    })
    basketContext.toggle(true)
  }

  const onQuoteSuccessMerch = (state: RequestQuoteFormStateType) => {
    const price = variant ? variant.price : product.price
    tracker.trackConversion("request_quote_merch", state.quantity * price)
  }

  const onQuoteSuccessGeneral = (state: RequestQuoteFormStateType) => {
    const price = variant ? variant.price : product.price
    tracker.trackConversion("request_quote_general", state.quantity * price)
  }

  const clearSelections = () => {
    setSelectedAttributes({})
    setVariant(null)
  }

  const renderAlertMessage = () => {
    return (
      <OutOfStock>
        {outOfStock && (
          <span>
            Out of stock. Please email us at outofstock@socialsupermarket.org to be notified when
            back in stock.
          </span>
        )}

        {isVendorFrozen(product.vendor) && (
          <span>
            This brand is currently not taking orders and will resume on{" "}
            {product.vendor.resumeShipmentDate}
          </span>
        )}

        {isVendorPaused(product.vendor) && !product.vendor.freezeOrders && (
          <span>
            This brand is accepting orders but will resume shipping on{" "}
            {product.vendor.resumeShipmentDate}
          </span>
        )}

        {/*{!isVendorPaused(product.vendor) && isVendorPastEventDate(product.vendor) && (*/}
        {/*  <span>Please be aware this may not arrive in time for Christmas.</span>*/}
        {/*)}*/}
      </OutOfStock>
    )
  }

  return (
    <div>
      <Layout>
        <Seo title={product.name} description={product.shortDescription} />
        {taxonomyLeaf && (
          <BreadcrumbContainer>
            <Breadcrumbs taxonomy={taxonomyLeaf} linkLeaf />
          </BreadcrumbContainer>
        )}
        <Container>
          <GalleryContainer>
            <ProductImageGallery product={product} variant={variant} />
          </GalleryContainer>
          <ContentContainer>
            <Title>{product.name}</Title>
            {product.brand && !isQuotable && (
              <BrandLinkContainer>
                By <BrandLink to={`/brand/${product.brand?.slug}`}>{product.brand?.name}</BrandLink>
              </BrandLinkContainer>
            )}
            {renderAlertMessage()}
            <Price>{getFormattedPriceRange(product)}</Price>
            {soonestDeliveryDate && (
              <PreOrderText>
                This product is a pre order, and will be available to ship from{" "}
                {soonestDeliveryDate}.
              </PreOrderText>
            )}
            <ProductShortDescription shortDescription={product.shortDescription} />

            {product.moq > 1 && (
              <Moq>
                <b>Minimum Order Quantity:</b> {product.moq}
              </Moq>
            )}
            {product.volumeDiscounts?.length > 0 && (
              <VolumeDiscounts product={product} variants={variant ? [variant] : undefined} />
            )}

            <AddToBasketForm>
              {product.slug === "eco-friendly-gift-card" && (
                <GiftCardAttributeFields
                  value={giftCardAttributes}
                  onChange={event => {
                    setGiftCardAttributes(prev => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }))
                  }}
                />
              )}
              {product.attributes && product.variants && (
                <ProductAttributeFields
                  selectedAttributes={selectedAttributes}
                  attributeOptions={product.attributes}
                  variants={product.variants}
                  onChange={handleAttributeChange}
                />
              )}

              {!isQuotable && (
                <ButtonContainer>
                  <CalculatedPrice>
                    {getFormattedPriceRange(product, variant ? [variant] : null, quantity)}
                  </CalculatedPrice>
                  <StyledProductQuantityInput
                    product={product}
                    quantity={quantity}
                    onChange={(value: number) => setQuantity(value)}
                  />
                  <PrimaryButton
                    disabled={outOfStock || isVendorFrozen(product.vendor)}
                    onClick={addToBasket}
                  >
                    Add to Basket
                  </PrimaryButton>
                </ButtonContainer>
              )}

              {isQuotable && (
                <RequestQuoteFormWrapper
                  baseUrl={getConfig().baseUrl}
                  product={product}
                  variant={variant}
                  onSuccess={onQuoteSuccessMerch}
                  mqlContactSource={"Quote Request"}
                  formName={"RequestQuoteForm"}
                />
              )}

              <OutOfStockClearSelectionsContainer>
                {product.attributes && Object.keys(selectedAttributes).length > 0 && (
                  <div>
                    <LinkButton style={{ marginTop: 0 }} onClick={clearSelections}>
                      Clear Selections
                    </LinkButton>
                  </div>
                )}

                {renderAlertMessage()}
              </OutOfStockClearSelectionsContainer>
              {!isQuotable && <RequestQuoteBox product={product} variant={variant} />}
              {!isQuotable && product.brand?.impactIcons && (
                <BrandPointsContainer>
                  <ImpactIcons slugString={product.brand?.impactIcons} columns={6} />
                </BrandPointsContainer>
              )}
            </AddToBasketForm>
            <AccordionContainer>
              <ProductDetailsAccordion product={product} variant={variant} />
            </AccordionContainer>
          </ContentContainer>
        </Container>

        {product.relatedProducts && product.relatedProducts?.length > 0 && (
          <ProductListContainer>
            <FeatureTitle title={"You May Also Like"} style={{ marginBottom: 40 }} />
            <ProductList
              products={filterByVisibility(product.relatedProducts, "Marketplace")}
              collectionName="Recommendations"
            />
          </ProductListContainer>
        )}
      </Layout>
    </div>
  )
}

const findVariantFromAttributes = (selectedAttributes: object, variants: VariantType[]) => {
  if (!variants) {
    return null
  }

  const perfectMatch = variants.find(variant =>
    variant.attributes.every(variantAttributes =>
      equalsIgnoreCase(
        selectedAttributes[cleanString(variantAttributes.name)],
        variantAttributes.value
      )
    )
  )

  if (perfectMatch) {
    return perfectMatch
  }

  const inStockPartialMatch = variants.find(
    variant =>
      variant.attributes.some(variantAttributes =>
        equalsIgnoreCase(
          selectedAttributes[cleanString(variantAttributes.name)],
          variantAttributes.value
        )
      ) && variant.stockStatus === "IN_STOCK"
  )

  if (inStockPartialMatch) {
    return inStockPartialMatch
  }

  return variants.find(variant =>
    variant.attributes.some(variantAttributes =>
      equalsIgnoreCase(
        selectedAttributes[cleanString(variantAttributes.name)],
        variantAttributes.value
      )
    )
  )
}

export default ProductPage
