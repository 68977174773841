import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { NutritionInfoType } from "model"

const Container = styled.div`
  margin-bottom: 30px;
`
const ProductName = styled.div`
  text-transform: uppercase;
  font-size: 1.1em;
  margin-bottom: 10px;
`
const Section = styled.div`
  margin-bottom: 20px;
`
const SectionTitle = styled.div`
  font-weight: bold;
`
const SectionContent = styled.div``
const NutritionSection = styled.div`
  width: 250px;
`
const NutritionLine = styled.div`
  display: flex;
  border-bottom: 1px solid gray;
`
const Left = styled.div`
  flex: 1;
  align-items: center;
  width: 300px;
`
const Right = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

interface Props {
  nutrition: NutritionInfoType
}

const NutritionInfoLine: FC<Props> = ({ nutrition: { name, nutrition } }) => {
  return (
    <Container>
      <ProductName>{name}</ProductName>
      <Section>
        <SectionTitle>Ingredients</SectionTitle>
        <SectionContent>{nutrition.ingredients}</SectionContent>
      </Section>
      <Section>
        <SectionTitle>Allergens</SectionTitle>
        <SectionContent>{nutrition.allergens}</SectionContent>
      </Section>
      <NutritionSection>
        <NutritionLine>
          <Left>
            <b>Typical Values</b>
          </Left>
          <Right>
            <b>Per 100g</b>
          </Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Energy (Kj)</Left>
          <Right>{nutrition.energyKj || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Energy (Kcal)</Left>
          <Right>{nutrition.energyKc || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Fat (g)</Left>
          <Right>{nutrition.fat || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Fat of which saturates (g)</Left>
          <Right>{nutrition.saturatedFat || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Carbohydrate (g)</Left>
          <Right>{nutrition.carbs || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Carbohydrate of which sugars (g)</Left>
          <Right>{nutrition.carbsSugar || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Protein (g)</Left>
          <Right>{nutrition.protein || 0}</Right>
        </NutritionLine>
        <NutritionLine>
          <Left>Salt (g)</Left>
          <Right>{nutrition.salt || 0}</Right>
        </NutritionLine>
      </NutritionSection>
    </Container>
  )
}

export default NutritionInfoLine
