import * as React from "react"
import Accordion from "../../components/accordion/Accordion"
import AccordionItem from "../../components/accordion/AccordionItem"
import AccordionHeader from "../../components/accordion/AccordionHeader"
import AccordionBody from "../../components/accordion/AccordionBody"
import GLink from "../../../gatsby/GLink"
import styled from "styled-components"
import IconLinkButton from "../../components/button/IconLinkButton"
import NutritionInfoLine from "./NutritionInfoLine"
import { ProductType, VariantType } from "model"

const LinkContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`
const VariantSection = styled.div`
  margin-bottom: 20px;
`
const AllergenLink = styled(GLink)`
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
  text-transform: none !important;
`

interface PropsType {
  product: ProductType
  variant: VariantType
}

const ProductDetailsAccordion = ({ product, variant }: PropsType) => {
  const items = getItems(product, variant)
  return items.length > 0 ? <Accordion>{items}</Accordion> : <div />
}

const getItems = (product: ProductType, variant: VariantType) => {
  const items = []

  const contentsItem = getContentsItem(product, variant)

  if (contentsItem) {
    items.push(contentsItem)
  }

  if (product?.description) {
    items.push(
      <AccordionItem>
        <AccordionHeader>Details</AccordionHeader>
        <AccordionBody>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
        </AccordionBody>
      </AccordionItem>
    )
  }

  if (product.brand?.description) {
    items.push(
      <AccordionItem>
        <AccordionHeader>About {product.brand?.name}</AccordionHeader>
        <AccordionBody>
          <div dangerouslySetInnerHTML={{ __html: product.brand?.description }} />
          <LinkContainer>
            <IconLinkButton to={`/brand/${product.brand?.slug}`}>
              Read more about {product.brand?.name}
            </IconLinkButton>
          </LinkContainer>
        </AccordionBody>
      </AccordionItem>
    )
  }

  const nutritionInfo = product.nutritionInfo || variant?.nutritionInfo

  if (nutritionInfo) {
    items.push(
      <AccordionItem>
        <AccordionHeader>Nutrition Info</AccordionHeader>
        <AccordionBody>
          <AllergenLink target="_blank" to={"/allergen-statement"}>
            Social Supermarket Allergen Statement
          </AllergenLink>
          {nutritionInfo.map(nutrition => (
            <NutritionInfoLine nutrition={nutrition} />
          ))}
        </AccordionBody>
      </AccordionItem>
    )
  }

  return items
}

const getContentsItem = (product: ProductType, variant: VariantType) => {
  const dynamicContents = product.dynamicContents || variant?.dynamicContents

  if (dynamicContents) {
    return (
      <AccordionItem>
        <AccordionHeader>Contents</AccordionHeader>
        <AccordionBody>
          {dynamicContents.map(c => (
            <div>
              {c.quantity} x {c.name}
            </div>
          ))}
        </AccordionBody>
      </AccordionItem>
    )
  } else if (!variant && product.variants && product.variants.some(v => v.dynamicContents)) {
    return (
      <AccordionItem>
        <AccordionHeader>Contents</AccordionHeader>
        <AccordionBody>
          {product.variants.map(v => (
            <VariantSection>
              <Title>{v.name}</Title>
              {v.dynamicContents.map(c => (
                <div>
                  {c.quantity} x {c.name}
                </div>
              ))}
            </VariantSection>
          ))}
        </AccordionBody>
      </AccordionItem>
    )
  } else if (variant && variant.description) {
    return (
      <AccordionItem>
        <AccordionHeader>Contents</AccordionHeader>
        <AccordionBody>
          <div dangerouslySetInnerHTML={{ __html: variant.description }} />
        </AccordionBody>
      </AccordionItem>
    )
  } else if (product.contents) {
    return (
      <AccordionItem>
        <AccordionHeader>Contents</AccordionHeader>
        <AccordionBody>
          <div dangerouslySetInnerHTML={{ __html: product.contents }} />
        </AccordionBody>
      </AccordionItem>
    )
  }
}

export default ProductDetailsAccordion
