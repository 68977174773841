import * as React from "react"
import styled from "styled-components"
import { cleanString, safeHeDecode } from "../../util/generalUtil"
import { equalsIgnoreCase } from "../../util/generalUtil"
import { AttributeOptionsType, VariantType } from "model"

const Container = styled.div``

const Option = styled.div`
  margin-bottom: 10px;

  select,
  input,
  textarea {
    width: 100%;
  }

  textarea {
    height: 200px;
  }

  select {
    background: none;
  }
`

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
`

interface PropsType {
  selectedAttributes: object
  attributeOptions: AttributeOptionsType[]
  variants: VariantType[]
  onChange: (name: string, value: string) => void
}

const ProductAttributeFields = ({
  selectedAttributes,
  attributeOptions,
  variants,
  onChange,
}: PropsType) => {
  return (
    <Container>
      {attributeOptions.map(attribute => (
        <Option>
          <Label>{safeHeDecode(attribute.name)}</Label>
          <select
            required
            value={selectedAttributes[cleanString(attribute.name)] || ""}
            onChange={event => onChange(cleanString(attribute.name), event.target.value)}
          >
            <option value="" disabled selected>
              Choose an option..
            </option>
            {attribute.options.map(option => (
              <option
                value={option.toLowerCase()}
                disabled={!attributeHasVariants(attribute.name, option, variants)}
              >
                {option}
              </option>
            ))}
          </select>
        </Option>
      ))}
    </Container>
  )
}

const attributeHasVariants = (name: string, option: string, variants: VariantType[]): boolean => {
  return variants.some(variant => {
    const someAttributes = variant.attributes.some(attr => {
      const allEquals = equalsIgnoreCase(attr.name, name) && equalsIgnoreCase(attr.value, option)
      return allEquals
    })

    return someAttributes && variant.stockStatus === "IN_STOCK"
  })
}

export default ProductAttributeFields
